import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import { loadWistiaScript } from '@helpers/load-wistia-script'
import { initWistiaVideo } from '@helpers/init-wistia-video'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import imageUrl from '../../images/arrow-video.png'
import PageContainer from '@system/page-container'
import ContentContainer from '../system/content-container'

const useStyles = makeStyles((theme) => ({
  video: {
    // matching full page width at all times will require
    // a screenwidth variable passed to makeStyles or similar
    '& .wistia_embed': {
      margin: '0 auto',
      backgroundColor: theme.palette.text.secondary,
      position: 'relative',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',

      '&:after': {
        display: 'block',
        content: "''",
        position: 'absolute',
        width: '70px',
        height: '70px',
        borderRadius: '100%',
        background: `#fff url(${imageUrl}) no-repeat 26px 50%`,
        backgroundSize: '24.78px 28.22px',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        transition: 'all 0.5s',
        pointerEvents: 'none',
      },
      '&:hover': {
        '&>div': {
          opacity: 1,
        },
      },
      '&.playing': {
        '&>div': {
          opacity: 1,
        },

        '&:after': {
          opacity: 0,
        },
      },
    },
  },
}))

let _video

const WistiaVideoModule = (props) => {
  const [playing, setState] = useState(false)
  const classes = useStyles()

  const wistiaId = props.blok.wistiaVideoId
  const wistiaRef = useRef()

  useEffect(() => {
    loadWistiaScript()
  }, [])

  const handleVideoInit = (video) => {
    _video = video
    _video.bind('play', (_) => setState(true))
    _video.bind('pause', (_) => setState(false))
    _video.bind('end', (_) => setState(false))
  }

  useEffect(() => {
    props.setWistiaRef && props.setWistiaRef(wistiaRef)
    initWistiaVideo(wistiaId, handleVideoInit)
  }, [wistiaId])

  useEffect(() => {
    if (_video) {
      initWistiaVideo(wistiaId, handleVideoInit)
      _video.replaceWith(wistiaId)
      setState(false)
    }
  }, [wistiaId])

  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer parentWidth={!props.blok.hasContainer}>
          {!!props.blok.wistiaVideoId && (
            <Box ref={wistiaRef} className={classes.video}>
              <Box
                className={`wistia_embed wistia_async_${wistiaId} playButton=false videoFoam=true ${
                  playing ? 'playing' : ''
                }`}
              ></Box>
            </Box>
          )}
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default WistiaVideoModule
